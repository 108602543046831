import React from 'react';
import {Carousel} from 'react-bootstrap'

function EntrepotCarousel({entrepot}) {
    function Item()
    {
        // const h1 = {
        //     color:"#fff"
        // }
        return (
            <>
            <Carousel >
            <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src= {entrepot.image !== null ? entrepot.carrousel_entrepots[0].image[0].formats.large.url : "..."} 
                alt="First slide"
                />
                <Carousel.Caption>
                {/* <div className="h1-card">
                <h1 style={h1}>Entrepot img1</h1>
                </div> */}
                </Carousel.Caption>
            </Carousel.Item>
            {/* <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src= {entrepot.image !== null ? API_URL + entrepot.carrousel_entrepots[1].image[0].url : "..."} 
                alt="First slide"
                />
                <Carousel.Caption>
                <div className="h1-card">
                <h1 style={h1}>Entrepot img2</h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src= {entrepot.image !== null ? API_URL + entrepot.carrousel_entrepots[2].image[0].url : "..."} 
                alt="First slide"
                />
                <Carousel.Caption>
                <div className="h1-card">
                <h1 style={h1}>Entrepot img3</h1>
                </div>
                </Carousel.Caption>
            </Carousel.Item> */}
            </Carousel>
         
             {/* <img src="https://www.codeproject.com/KB/GDI-plus/ImageProcessing2/img.jpg"
            // alt="img dog" />    
            // {console.log({bureau})} */}
             {/* <img src={bureau.image !== null ? API_URL + bureau.image[0].url : "..."} /> */}
            
        </>
        )
    }
    return (
        <>
        {
           <Item />
        }
    </>
    )
}



export default EntrepotCarousel

