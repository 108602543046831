import React from 'react'
import './card.css'
import ReactMarkdown from 'react-markdown'


function CardSalleReunion({salleReunion}) {
    const titre = {
        color:"#0c9029",
        paddingTop:50
    }

    return (
        <>
            <h2 style={titre}>{salleReunion.titre}</h2>
           <ReactMarkdown children = {salleReunion.description} />
        </>
    )
}

export default CardSalleReunion
