import React from 'react';
import {Carousel} from 'react-bootstrap'

function SalleReunionCarousel({salleReunion}) {
    function Item()
    {
        // const h1 = {
        //     color:"#fff"
        // }
        return (
            <>
            <Carousel >
            <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src= {salleReunion.image !== null ? salleReunion.carrousel_salles_reunions[0].image[0].formats.large.url : "..."} 
                alt="First slide"
                />
                {/*<Carousel.Caption>
                 <div className="h1-card">
                <h1 style={h1}>salleReunion img1</h1>
                </div> 
                </Carousel.Caption>*/}
            </Carousel.Item>
             <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src={salleReunion.image !== null ? salleReunion.carrousel_salles_reunions[1].image[0].formats.large.url : "..."} 
                alt="First slide"
                />
                {/* <Carousel.Caption>
                <div className="h1-card">
                <h1 style={h1}>salleReunion img2</h1>
                </div>
                </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item interval={3500}>
                <img
                className="d-block w-100"
                src={salleReunion.image !== null ? salleReunion.carrousel_salles_reunions[2].image[0].formats.large.url : "..."} 
                alt="First slide"
                />
                {/* <Carousel.Caption>
                <div className="h1-card">
                <h1 style={h1}>salleReunion img3</h1>
                </div>
                </Carousel.Caption> */}
            </Carousel.Item>
            </Carousel>
         
             {/* <img src="https://www.codeproject.com/KB/GDI-plus/ImageProcessing2/img.jpg"
            // alt="img dog" />    
            // {console.log({salleReunion})} */}
             {/* <img src={salleReunion.image !== null ? API_URL + salleReunion.image[0].url : "..."} /> */}
            
        </>
        )
    }
    return (
        <>
        {
           <Item />
        }
    </>
    )
}



export default SalleReunionCarousel

