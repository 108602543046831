import React from 'react'
import { Grid, Paper } from '@material-ui/core'

import Navbar from './Navbar'
import Bureaux from './bureaux'
import Entrepot from './entrepots'
import SallesReunions from './salleReunions'
import Contact from './Contact'
import Footer from './Footer'

import background from './img/Coworking.png'
import batiment from './img/Batiment 1.jpg'

import './accueil.css'

function Accueil() {
    return (
        <>
            <Navbar />
                <div style={{
                    backgroundImage: `url(${background})`, backgroundSize: 'cover', height: 400, textAlign: 'center', margin: 'auto',
                    padding: '10px', color: '#fff'
                }}><br />
                    <h3>
                        Location de bureaux individuels ou en co-working, salle de réunion et espace de stockage
                    </h3><br />
                    <p><strong>
                        Sur la zone de Saint-Quentin-en-Yvelines, Axsol Coworking propose la location mensuelle de bureaux équipés (bureaux fermés ou partagés), une salle de réunion et des espaces de stockage au m2 ou en racks.
                        La société est implantée dans la Z.A de Trappes-Elancourt, et est facile d’accès
                        Les locaux sont sécurisés et sous alarme et vidéosurveillance. Des places de parking sont également disponibles pour le stationnement lors de votre venue.
                    </strong>
                    </p></div>

                <div className="vl" style={{ borderTop: ' 20px solid green' }}></div>
                    <div className="marginTest">
                <div>
                    <Bureaux />
                </div>
                <div>
                    <SallesReunions />
                </div>
                <div>
                    <Entrepot />
                </div>
                <br />
                <div className="vl" style={{ borderTop: ' 4px solid green' }}></div>
                <Grid container >
                    <Grid item xs={12}>
                        <h2 style={{ color: "#0c9029", marginTop: 20 }}>Contactez-nous</h2>
                        <p style={{ textAlign: 'center' }}>Vous avez des questions? Vous souhaitez un devis? Notre équipe est à votre disposition pour vous répondre dans les meilleurs délais !</p>
                    </Grid>
                    <Grid item sm={6}>
                        <Contact />
                        <Paper className="paperMap2" style={{width:412, marginTop: 50 }}>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.8849150548594!2d1.9811010158510514!3d48.764994015232006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e68504d627cc85%3A0x4ab374e3b3a555c7!2sAXSOL!5e0!3m2!1sfr!2sfr!4v1623247887925!5m2!1sfr!2sfr" title="Localisation-AxsolCoworking" className="map2" allowfullscreen="" loading="lazy"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.8849150548594!2d1.9811010158510514!3d48.764994015232006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e68504d627cc85%3A0x4ab374e3b3a555c7!2sAXSOL!5e0!3m2!1sfr!2sfr!4v1624633737795!5m2!1sfr!2sfr"  title="Localisation-AxsolCoworking" className="map2" allowfullscreen="" loading="lazy"></iframe>
                        </Paper>
                    </Grid>
                    <Grid className="gridTop" item xs={3}>
                        <Paper className="paperBatiment" style={{ width: 490, marginTop: 50, position: 'absolute' }}>
                            <img src={batiment} style={{ width: 494 }} alt="bâtiment Axsol" />
                        </Paper>
                    </Grid><br />
                    <Grid className="gridBot" item xs={3}>
                        <Paper className="paperMap" style={{ width:320, height:224, position: 'relative', top: 340, right: 290 }}>
                            {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.8849150548594!2d1.9811010158510514!3d48.764994015232006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e68504d627cc85%3A0x4ab374e3b3a555c7!2sAXSOL!5e0!3m2!1sfr!2sfr!4v1623247887925!5m2!1sfr!2sfr" title="Localisation-AxsolCoworking" className="map" allowfullscreen="" loading="lazy"></iframe> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2629.8849150548594!2d1.9811010158510514!3d48.764994015232006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e68504d627cc85%3A0x4ab374e3b3a555c7!2sAXSOL!5e0!3m2!1sfr!2sfr!4v1624633737795!5m2!1sfr!2sfr" title="Localisation-AxsolCoworking" className="map" allowfullscreen="" loading="lazy"></iframe>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <Footer />
        </>
    )
}

export default Accueil
