import React, {useEffect,useState} from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'

import CardEntrepot from './CardEntrepot'
import EntrepotCarousel from './EntrepotCarousel'



function Entrepot () {
    const [isLoading,setIsLoading]= useState(true)
    const [entrepot, setEntrepot] = useState(null)

    useEffect(() => {
        fetch('https://axsol-coworking.herokuapp.com/entrepots',
        {
            method: "GET",
            headers: {
                'Accept': 'Application/json'
            }
        })
        .then (res => res.json())
        .then (response => {
                setEntrepot(response)
                setIsLoading(false)
        })
    }, [])
    return (
        <div className="entrepots" id="entrepot">
            
            <Grid container >

            <Grid className="entrepotDescription" item sm={6} >
                <div>

                {isLoading ? 
                <Box>
                    <Skeleton width="60%" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                </Box> : entrepot.map(entrepot => <CardEntrepot entrepot={entrepot} key={entrepot.id} />)}
                </div>
            </Grid>
            <Grid className="entrepotImage" item sm={6} >
                <Paper>
            {isLoading ? 
                <Box>
                    <Skeleton variant="rect" width={210} height={118} />
                </Box> : entrepot.map(entrepot => <EntrepotCarousel entrepot={entrepot} key={entrepot.id} />)}
                    </Paper>
            </Grid>            
            </Grid>
        </div>
    )
}

export default Entrepot