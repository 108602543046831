import React, { useEffect, useState } from 'react'
import { HashLink as Link } from 'react-router-hash-link'
import { FaBars, FaTimes } from 'react-icons/fa'
// import { Button } from './Button';
import { IconContext } from 'react-icons/lib'
import logoVert from '../components/img/logo.jpg'
import './Navbar.css'

function Navbar() {
    const [click, setClick] = useState(false);
    const [,setButton] = useState(true);

    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);

    const showButton = () => {
        if (window.innerWidth <= 960) {
            setButton(false);
        } else {
            setButton(true);
        }
    };

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton);




    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className="navbar">
                    <div className="logo-image">
                        <Link to='/' className="navbar-logo" onClick={closeMobileMenu}>
                            <img src={logoVert} className="navbar-icon" alt="logoAmodi" />
                        </Link>
                    </div>
                    <div className="navbar-container container">
                        <div className="menu-icon" onClick={handleClick}>
                            {click ? <FaTimes /> : <FaBars />}
                        </div>
                        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                            <li className='nav-item'>
                                <Link to="#bureau" className='nav-links' onClick={closeMobileMenu}>
                                    BUREAUX
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="#salleReunion" className='nav-links' onClick={closeMobileMenu}>
                                    SALLES DE RÉUNIONS
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="#entrepot" className='nav-links' onClick={closeMobileMenu}>
                                    ESPACE DE STOCKAGE
                                </Link>
                            </li>
                            <li className='nav-item'>
                                <Link to="#contact" className='nav-links' onClick={closeMobileMenu}>
                                    CONTACT
                                </Link>
                            </li>
                            {/* <li className='nav-btn'>
                    {button ? (
                    <Link to='/' className='btn-link'>
                     <Button buttonStyle='btn--outline'>test</Button>
                    </Link>
                    ):(
                    <Link to='/' className='btn-link'>
                     <Button buttonStyle='btn--outline' buttonSize='btn--mobile'>
                     toto
                     </Button>
                    </Link>
                    )}
                    
                        </li> */}
                        </ul>
                    </div>
                </div>
            </IconContext.Provider>
        </>
    )
}

export default Navbar
