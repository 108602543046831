import React from 'react';
import { Route,BrowserRouter as Router, Switch } from 'react-router-dom';
//import Container from '@material-ui/core/Container'
import Accueil from './components/Accueil';


// const fullScreen = {
//   width: "100vw !important"
// }

function App() {
  return (
    // <Container>
      <div className="App" >
      <Router>
        {/* <Navbar/> */}
        <Switch>
      
          <Route path='/' exact component={Accueil}/>
      
        </Switch>
        {/* <Footer /> */}
      </Router>
      </div>
    // </Container>
  );
}

export default App;
