import React, {useEffect,useState} from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import {Skeleton} from '@material-ui/lab'

import CardSalleReunion from './CardSalleReunion'
import SalleReunionCarousel from './SalleReunionCarousel'
import useWindowDimensions from './dimension'

function SallesReunions () {
     // eslint-disable-next-line
    const { height, width } = useWindowDimensions(); 
    const [isLoading,setIsLoading]= useState(true)
    const [sallesReunions, setSallesReunions] = useState(null)

    useEffect(() => {
        fetch('https://axsol-coworking.herokuapp.com/salle-reunions',
        {
            method: "GET",
            headers: {
                'Accept': 'Application/json'
            }
        })
        .then (res => res.json())
        .then (response => {
                setSallesReunions(response)
                setIsLoading(false)
        })
    }, [])
    return (
        <div className="sallesReunions" id="salleReunion">
                {
                    width<=600 ?  <Grid container >
                        <Grid className="salleReunionDescription" item sm={6} >
                        <div style={{paddingLeft:20}}>
        
                        {isLoading ? 
                        <Box>
                            <Skeleton width="60%" />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Box> : sallesReunions.map(salleReunion => <CardSalleReunion salleReunion={salleReunion} key={salleReunion.id} />)}
                        </div>
                    </Grid>
                    <Grid className="salleReunionImage" item sm={6} >
                        <Paper>
                    {isLoading ? 
                        <Box>
                            <Skeleton variant="rect" width={210} height={118} />
                        </Box> : sallesReunions.map(salleReunion => <SalleReunionCarousel salleReunion={salleReunion} key={salleReunion.id} />)}
                            </Paper>
                    </Grid>
                    
                    
                    </Grid>
                    : 
                    <Grid container >
                    <Grid className="salleReunionImage" item sm={6} >
                        <Paper>
                    {isLoading ? 
                        <Box>
                            <Skeleton variant="rect" width={210} height={118} />
                        </Box> : sallesReunions.map(salleReunion => <SalleReunionCarousel salleReunion={salleReunion} key={salleReunion.id} />)}
                            </Paper>
                    </Grid>
                    <Grid className="salleReunionDescription" item sm={6} >
                        <div style={{paddingLeft:20}}>
        
                        {isLoading ? 
                        <Box>
                            <Skeleton width="60%" />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </Box> : sallesReunions.map(salleReunion => <CardSalleReunion salleReunion={salleReunion} key={salleReunion.id} />)}
                        </div>
                    </Grid>
                    
                    </Grid>}
           
        </div>
    )
}

export default SallesReunions
