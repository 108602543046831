import React from 'react';
import './Footer.css';
import logoBleu from './img/logo.jpg'
import mentionsLegales from './docs/AXSOL_COWORKING-MENTIONS_LEGALES.pdf'
import { Link } from 'react-router-dom';
import { FiSmartphone, FiMail } from 'react-icons/fi';
import { FaMapMarkerAlt } from 'react-icons/fa';

function Footer() {

  // const img = {
  //   // paddingTop: "55px",
  //   // paddingRight: "10px"
  //   // display: "flex",
  //   // justifyContent: "center"
  // }

  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        {/* <p className='footer-subscription-heading'>
          Join our exclusive membership to receive the latest news and trends
        </p>
        <p className='footer-subscription-text'>
          You can unsubscribe at any time.
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div> */}
      </section>
      <div className='footer-links'>
        <div className='footer-logo'>
          <Link to='/' className='social-logo'>
            <img src={logoBleu} className="footer-icon" alt="logoAmodi" width="250px" />
          </Link>
        </div>
        <div className='footer-link-wrapper'>
        <div className='footer-link-items' style={{width: 180}}>
            <h2>ADRESSE</h2>
            <Link to='/'><FaMapMarkerAlt /> 45 avenue Georges Politzer - 78190 TRAPPPES France</Link>
          </div>
          <div className='footer-link-items'  style={{width: 180, color:"#fff"}}>
            <h2>CONTACT </h2>
            <Link to='/'> AXSOL COWORKING</Link>
            <a href="tel:+33176781959"><FiSmartphone />+33 (0)1 76 78 19 59</a>
            <a href="mailto:location@axsol.fr"><FiMail /> location@axsol.fr</a>
            <p>Du lundi au vendredi</p>
            <p>9h00 - 12h30 / 13h30 - 17h</p>
            

          </div>
          <div className='footer-link-items' style={{width: 180}}>
          <h2 className="h2Hidden" style={{color:"#0c9029"}}>mentionsLegales</h2>
            <a href={mentionsLegales} target="_blank" rel="noreferrer">Mentions Légales</a>
          </div>
          
          {/* <div className='footer-link-items'>
            <h2>PLAN</h2>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2625.2581648375076!2d1.9878898156394762!3d48.85328727928698!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e685f272d7120f%3A0xb21330c64078e463!2s3%20Rue%20des%20Peupliers%2C%2078450%20Chavenay!5e0!3m2!1sfr!2sfr!4v1618411923390!5m2!1sfr!2sfr" title="Localisation-Amodi" className="map" allowfullscreen="" loading="lazy"></iframe>
          </div> */}
        </div>
      </div>
      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo2'>
            <Link to='/' className='social-logo'>
              <img src={logoBleu} className="footer-icon" alt="logoAxsolCoworking" width="150" />
            </Link>
          </div>
          <small className='website-rights'>AXSOL COWORKING © 2021</small>
          <div className='social-icons'>
            {/* Juste là pour garder Amodi 2020 au centre */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;