import React from 'react'
import ReactMarkdown from 'react-markdown'


function CardEntrepot({entrepot}) {
    const titre = {
        color:"#0c9029",
        paddingTop:50

    }

    return (
        <>
            <h2 style={titre}>{entrepot.titre}</h2><br />
            <ReactMarkdown children = {entrepot.contenu} />
        </>
    )
}

export default CardEntrepot
