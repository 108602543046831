import React, { useState } from "react";
import emailJs from 'emailjs-com'
import { templateId, serviceId, userId } from '../config'
import './contact.css'

const App = () => {
    const [name, setName] = useState("");
    const [prenom, setPrenom] = useState("");
    const [company, setCompany] = useState("");
    // const [date, setDate] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [commentaire, setCommentaire] = useState("");

    const isEmail = () => {
        let mail = document.getElementById('not-mail')
        let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

        if (email.match(regex)) {
            mail.style.display = 'none'
            return true;
        } else {
            mail.style.display = "block"
            mail.style.animation = 'dongle 1s'
            setTimeout(() => {
                mail.style.animation = 'none'
            }, 1000)
            return false;
        }
    }

    const isPhone = ()=> {
    let tel = document.getElementById('not-phone')
     // eslint-disable-next-line
    let regex = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im

    if (phone.match(regex)) {
        tel.style.display = 'none'
        return true;
    } else {
        tel.style.display = "block"
        tel.style.animation = 'dongle 1s'
        setTimeout(() => {
            tel.style.animation = 'none'
        }, 1000)
        return false;
    }
    }

    const failMessage = (message) => {
        let formMess = document.querySelector('.form-commentaire')

        formMess.innerHTML = message
        formMess.style.opacity = "1"
        formMess.style.background = '#ff4d4d'

        document.getElementById('name').classList.add('error')
        document.getElementById('prenom').classList.add('error')
        document.getElementById('company').classList.add('error')
        document.getElementById('phone').classList.add('error')
        document.getElementById('email').classList.add('error')
        document.getElementById('commentaire').classList.add('error')
    }
    
    const successMessage = () => {
        let formMess = document.querySelector('.form-commentaire')
        
        formMess.innerHTML = 'Commentaire envoyé ! Nous vous recontacterons dès que possible';
        formMess.style.background = 'green'
        formMess.style.opacity = '1'
        document.getElementById('name').classList.remove('error')
        document.getElementById('prenom').classList.remove('error')
        document.getElementById('company').classList.remove('error')
        document.getElementById('phone').classList.remove('error')
        document.getElementById('email').classList.remove('error')
        document.getElementById('commentaire').classList.remove('error')
        
        setTimeout(() => {
            formMess.style.opacity = '0'
        }, 5000)
    }
    const handleSubmit = (e) => {
        e.preventDefault();


        const sendFeedback = (templateId, variables) => {

            emailJs
                .send(serviceId, templateId, variables, userId)
                .then((res) => {
                    successMessage()
                    setName("");
                    setPrenom("");
                    setCompany("");
                    setPhone("");
                    // setDate("");
                    setEmail("");
                    setCommentaire("");
                })
                .catch(
                    (err) => { failMessage("Une erreur s'est produite, veuillez réessayer.") }
                )
        };


        if (name && prenom && isEmail() && isPhone() && commentaire && company) {
            sendFeedback(templateId, {
                name,
                prenom,
                company,
                // date,
                phone,
                email,
                commentaire,
            });
        } else {
            failMessage('Merci de remplir les champs requis *')
        }
    };


    return (
        <>
            
            <form className="contact-form" id="contact" style={{marginBottom: '25px'}}>
                <br/><h4><strong>Coordonnées</strong></h4>
                <div className="form-content">
                    <input
                        type="text"
                        id="company"
                        name="company"
                        onChange={(e) => setCompany(e.target.value)}
                        placeholder="Nom de la société*"
                        value={company}
                    />
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Nom *"
                        value={name}
                        autoComplete="off"
                    />

                    <input
                        type="text"
                        id="prenom"
                        name="prenom"
                        onChange={(e) => setPrenom(e.target.value)}
                        placeholder="Prénom *"
                        value={prenom}
                        autoComplete="off"
                    />
                    <div className="email-content">
                    <label id="not-phone">Numéro non valide</label>
                    <input
                        type="text"
                        id="phone"
                        name="phone"
                        onChange={(e) => setPhone(e.target.value)}
                        placeholder="Téléphone *"
                        value={phone}
                    />
                </div>
                    <div className="email-content">
                        <label id="not-mail">Email non valide</label>
                        <input
                            type="mail"
                            id="email"
                            name="email"
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Email *"
                            value={email}
                            autoComplete="off"
                        />
                    </div>
                    
                    <h4><strong>Détail de votre projet</strong></h4>
                    {/* <input
                        type="text"
                        id="date"
                        name="date"
                        onChange={(e) => setDate(e.target.value)}
                        placeholder="Date de visite souhaitée "
                        value={date}
                        autoComplete="off"
                    /> */}
                    <textarea
                        id="commentaire"
                        name="commentaire"
                        onChange={(e) => setCommentaire(e.target.value)}
                        placeholder="Message *"
                        value={commentaire}
                    />
                </div>
                <input
                    className="button"
                    type="button"
                    value="ENVOYER"
                    onClick={handleSubmit}
                    
                />
                <div className="form-commentaire"></div>

            </form>
        </>
    );
};

export default App;