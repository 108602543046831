import React from 'react'
import ReactMarkdown from 'react-markdown'

function CardBureau({bureau}) {
    const titre = {
        color:"#0c9029",
        paddingTop:50
    }
  
   
    return (
        <>
            <h2 style={titre}>{bureau.titre}</h2>

            <ReactMarkdown  children={bureau.contenu} />
        </>
    )
}

export default CardBureau
