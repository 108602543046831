import React, { useEffect, useState } from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'

import CardBureau from './CardBureau'
import BureauCarousel from './BureauCarousel'

import useWindowDimensions from './dimension'

//import "./carouselResponsive.css"

function Bureaux() {

    const { width } = useWindowDimensions();
    const [isLoading, setIsLoading] = useState(true)
    const [bureaux, setBureaux] = useState(null)

    useEffect(() => {
        fetch('https://axsol-coworking.herokuapp.com/bureaux',
            {
                method: "GET",
                headers: {
                    'Accept': 'Application/json'
                }
            })
            .then(res => res.json())
            .then(response => {
                setBureaux(response)
                setIsLoading(false)
            })
    }, [])
    return (
        <div className="bureaux" id="bureau">
             {
                    width<=600 ?
                    <Grid container >

                   
                    <Grid className="bureauxDescription" item sm={6}  >
                        <div style={{paddingRight:20}}>
    
                            {isLoading ?
                                <Box>
                                    <Skeleton width="60%" />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box> : bureaux.map(bureau => <CardBureau bureau={bureau} key={bureau.id} />)}
                        </div>
                    </Grid>
                    <Grid className="bureauxImage" item sm={6} >
                        <Paper className="paperBureaux">
                            {isLoading ?
                                <Box>
                                    <Skeleton variant="rect" width={210} height={118} />
                                </Box> : bureaux.map(bureau => <BureauCarousel bureau={bureau} key={bureau.id} />)}
                        </Paper>
    
                    </Grid>
                </Grid> :
                <Grid container >

                   
                    <Grid className="bureauxDescription" item sm={6}  >
                        <div style={{paddingRight:20}}>
    
                            {isLoading ?
                                <Box>
                                    <Skeleton width="60%" />
                                    <Skeleton />
                                    <Skeleton />
                                    <Skeleton />
                                </Box> : bureaux.map(bureau => <CardBureau bureau={bureau} key={bureau.id} />)}
                        </div>
                    </Grid>
                    <Grid className="bureauxImage" item sm={6} >
                        <Paper className="paperBureaux">
                            {isLoading ?
                                <Box>
                                    <Skeleton variant="rect" width={210} height={118} />
                                </Box> : bureaux.map(bureau => <BureauCarousel bureau={bureau} key={bureau.id} />)}
                        </Paper>
    
                    </Grid>
                </Grid>}
            {/* <Grid container >

                <Grid className="paperBureaux2">{isLoading ?
                    <Box>
                        <Skeleton variant="rect" width={210} height={118} />
                    </Box> : bureaux.map(bureau => <BureauCarousel bureau={bureau} key={bureau.id} />)}</Grid>
                <Grid className="bureauxDescription" item sm={6}  >
                    <div style={{paddingRight:20}}>

                        {isLoading ?
                            <Box>
                                <Skeleton width="60%" />
                                <Skeleton />
                                <Skeleton />
                                <Skeleton />
                            </Box> : bureaux.map(bureau => <CardBureau bureau={bureau} key={bureau.id} />)}
                    </div>
                </Grid>
                <Grid className="bureauxImage" item sm={6} >
                    <Paper className="paperBureaux">
                        {isLoading ?
                            <Box>
                                <Skeleton variant="rect" width={210} height={118} />
                            </Box> : bureaux.map(bureau => <BureauCarousel bureau={bureau} key={bureau.id} />)}
                    </Paper>

                </Grid>
            </Grid> */}
        </div>
    )
}

export default Bureaux
